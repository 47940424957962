import React from 'react';
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from '../../layout/content';
import TileListing from "../../components/TileListing";
import SEO from "../../components/SEO/SEO";
import config from "../../../data/SiteConfig";
import styles from '../../layout/withSidebar.module.scss';

class GuidesIndex extends React.Component {
  render() {
    const { data } = this.props;
    const postEdges = data.allMarkdownRemark.edges;
    return (
      <Layout title='Plan Your Trip'>
        <Helmet title={config.siteTitle} />
        <SEO />
        <div className={styles.pageTitle}><h1>Articles</h1></div>
        <TileListing postEdges={postEdges} />
      </Layout>
    )
  }
}

export default GuidesIndex;

export const pageQuery = graphql`
  query GuideQuery {
    allMarkdownRemark(
      limit: 2000, 
      sort: {fields: [fields___date], order: DESC}
      filter: { frontmatter: { category: {eq: "guide" }}}
    ) {
      edges {
        node {
          fields {
            slug
            date
            locationName
            readingTime {
              text
            }
          }
          html
          excerpt(pruneLength: 500)
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
          }
        }
      }
    }
  }
`;